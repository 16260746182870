import '../../node_modules/bootstrap/dist/css/bootstrap.css';
import '../css/style.scss';

document.addEventListener('DOMContentLoaded', function () {
    // Handle call button click events
    const callButtons = document.getElementsByClassName('callButton');
    for (let i = 0; i < callButtons.length; i++) {
        callButtons[i].addEventListener('click', function () {
            window.location.href = 'tel:+32487315243';
        });
    }

    // Handle quote button click event
    const quoteButton = document.getElementById('quoteButton');
    if (quoteButton) {
        quoteButton.addEventListener('click', function () {
            const targetSection = document.getElementById('offerte');
            if (targetSection) {
                targetSection.scrollIntoView({behavior: 'smooth'});
            } else {
                console.error('Element with id "offerte-contact" not found.');
            }
        });
    } else {
        console.error('Button with id "quoteButton" not found.');
    }

    // Handle email button click event
    const emailButton = document.getElementById('emailButton');
    if (emailButton) {
        emailButton.addEventListener('click', function () {
            window.location.href = 'mailto:takeldienstdriveup@gmail.com';
        });
    } else {
        console.error('Button with id "emailButton" not found.');
    }

    // Handle navigation link click events to set active class
    const navLinks = document.querySelectorAll('.nav-link');
    navLinks.forEach(link => {
        link.addEventListener('click', function () {
            // Remove active class from all nav links
            navLinks.forEach(nav => nav.classList.remove('active'));

            // Add active class to the clicked nav link
            this.classList.add('active');
        });
    });

    // Handle form submission with AJAX
    const form = document.getElementById('offerteForm');
    if (form) {
        form.addEventListener('submit', function (event) {
            event.preventDefault(); // Prevent the form from submitting the traditional way

            const formData = new FormData(this);

            fetch('/submit', {
                method: 'POST',
                body: formData,
                headers: {
                    'Accept': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                }
            }).then(response => response.json())
                .then(data => {
                    const messageContainer = document.getElementById('messageContainer');
                    if (messageContainer) {
                        messageContainer.style.display = 'block';
                        if (data.success) {
                            messageContainer.innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
                                                            ${data.message}
                                                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                                        </div>`;
                            form.reset(); // Clear the form fields
                        } else {
                            messageContainer.innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert">
                                                            ${data.message}
                                                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                                        </div>`;
                        }
                    } else {
                        console.error('Message container not found.');
                    }
                }).catch(error => console.error('Error:', error));
        });
    } else {
        console.error('Form with id "offerteForm" not found.');
    }
});

document.addEventListener('DOMContentLoaded', (event) => {
    const form = document.getElementById('offerteForm');
    const flashMessage = document.getElementById('flash-message');

    form.addEventListener('submit', (event) => {
        flashMessage.style.display = 'block';
    });
});

document.querySelectorAll('.nav-link').forEach(function(link) {
        link.addEventListener('click', function() {
            var navbar = document.getElementById('navbarSupportedContent');
            var bsCollapse = new bootstrap.Collapse(navbar, {
                toggle: false
            });
            bsCollapse.hide();
        });
    });